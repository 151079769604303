body {
  min-width: 300px;
}

.App {
  display: flex;
  height: 100svh;
  overflow: hidden;
}

.portfolio-container, .logs {
  width: calc(50% - 16px);
  flex-grow: 0;
  flex-shrink: 0;
}

.no-portfolio {
  display: flex;
  align-items: center;
}

.portfolio-table-container {
  height: calc(100svh - 76px);
  margin-bottom: 8px;
}

.portfolio-container img.mtt-img {
  max-width: 100%;
}

.mtt-search-engine {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mtt-search-engine input[type="text"] {
  width: calc(100% - 32px);
  max-width: 420px;
}

.portfolio-present .mtt-search-engine {
  align-items: flex-start;
}

.portfolio-present > .mtt-search-engine-container {
  display: flex;
  margin: 8px;
  height: 51px;
}

@media only screen and (max-width: 1250px) {
  .portfolio-present > .mtt-search-engine-container {
    display: block;
    overflow: hidden;
  }
  .portfolio-present > .mtt-search-engine-container {
    height: 93px;
  }
  .portfolio-table-container {
    height: calc(100svh - 118px);
  }
  .portfolio-table tbody {
    max-height: calc(100svh - 155px);
  }
}

.portfolio-present img.mtt-img {
  max-width: 220px;
  width: 100%;
  object-fit: contain;
  margin-right: 16px;
}

input[type='text'] {
  margin-top: 4px;
  margin-bottom: 4px;
}

input[type='button'] {
  margin-right: 4px;
}

.logs {
  overflow: auto;
  position: relative;
  margin: 8px;
  border: darkgray 1px solid;
  padding: 8px;
  overflow-wrap: anywhere;
  background: black;
  color: #1aff24;
  font-family: "Lucida Console", "Lucida Sans Typewriter", monaco, "Bitstream Vera Sans Mono", monospace;
  font-size: 13px;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 18.5714px;
}

.logs-buttons {
  position: sticky;
  top: 0;
}

.logs > pre {
  text-wrap: pretty;
}

.portfolio-table {
  width: calc(100% - 16px);
  margin: 8px;
  border: darkgray 1px solid;
  height: 100%;
}

.portfolio-table tbody {
  display: block;
  overflow: auto;
  max-height: calc(100svh - 114px);
}

.portfolio-table thead tr{
  display: block;
}

.portfolio-table tr {
  height: 32px;
}

th.icon, td.icon {
  width: 50px;
  text-align: left;
}

th.name, td.name {
  max-width: 300px;
  width: 300px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

th.balance, td.balance {
  max-width: 300px;
  width: 300px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.portfolio-table img {
  height: 24px;
  width: 24px;
}

.log {
  margin-bottom: 10px;
}

.log .req {
  font-weight: 600;
}

.myIp {
  color: red;
}


.fingerprint {
  left: 10px;
  bottom: 10px;
  background: #000050;
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fingerprint > img {
  width: 74%;
}


@media only screen and (max-width: 900px) {
  .portfolio-table-container{
    font-size: 13px;
  }
  .logs {
    font-size: 10.5px;
  }
}


@media only screen and (max-width: 630px) {
  .App {
    display: flex;
    flex-direction: column;
  }

  .portfolio-container, .logs {
    width: 100%;
    height: calc(100svh / 2);
  }
  .portfolio-container {
    display: flex;
    flex-direction: column;
  }
  .portfolio-present > .mtt-search-engine-container {
    flex-shrink: 0;
    height: 97px;
  }
  .App .mtt-search-engine-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .portfolio-container.no-portfolio img.mtt-img {
    margin-top: 16svh;
  }
  .portfolio-present .mtt-search-engine {
    align-items: center;
  }
  .mtt-search-engine input[type="button"] {
    height: 24px;
    font-size: 16px;
  }
  .portfolio-table-container{
    margin: 0;
    border: none;
    font-size: 13px;
  }
  .portfolio-table{
    width: calc( 100% - 8px );
    margin: 0;
    margin-left: 8px;
    border: none;
  }
  .portfolio-table tbody {
    height: 100%;
  }
  .logs {
    padding: 8px 0 0 8px;
    width: calc(100% - 8px);
    border: none;
    margin: 0;
    flex-grow: 1;
  }

}


@media only screen and (max-width: 630px) {
  .mtt-search-engine input[type="text"] {
    width: calc(100svw - 32px);
  }
}

@media only screen and (max-width: 300px) {
  .mtt-search-engine input[type="text"] {
    width: calc(300px - 32px);
  }
}